import axios from "axios";

// const url = "http://127.0.0.1:8000/api/";

const url = "https://lmh-reports.hetadatain.com/api/";

export default url;

export const loginapi2 = async (email, password) => {
  try {
    const response = await axios.post(`${url}loginnew`, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
