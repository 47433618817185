// import React, { useState, useEffect } from "react";
// import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
// } from "@mui/material";
// import {
//   Menu as MenuIcon,
//   Logout,
//   AcUnitOutlined,
//   Description,
// } from "@mui/icons-material";
// import DatePickerComponent from "./DatePickerComponent";
// import SortName from "./SortName";
// import StudentReportDownloader from "./StudentReportDownloader";
// import "../style/main.css"; // Your custom styles

// const Main = (props) => {
//   const { login_status } = props;
//   const [currentDateTime, setCurrentDateTime] = useState(new Date());
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(""); // State for selected item

//   const handleLogoutCampus = () => {
//     console.log("Logged out from campus");
//     login_status(false);
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentDateTime(new Date());
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item); // Set selected item when clicked
//   };

//   const dateTimeBoxStyle = {
//     background: selectedItem
//       ? "linear-gradient(45deg, purple, orange)" // Match the gradient with the selected item
//       : "linear-gradient(45deg, purple, orange)", // Default gradient
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "4px",
//     textAlign: "center",
//     display: "flex",
//     alignItems: "center",
//     gap: "10px", // Space between icon and text
//   };

//   return (
//     <Router>
//       <div
//         style={{
//           fontFamily: "Comic Sans MS",
//           display: "flex",
//           flexDirection: "column",
//           minHeight: "100vh",
//         }}
//       >
//         {/* AppBar with custom background color */}
//         <AppBar position="sticky" sx={{ backgroundColor: "#fff" }}>
//           <Toolbar>
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//               sx={{
//                 background: drawerOpen
//                   ? "linear-gradient(45deg, blue, pink)" // Gradient when drawer is open
//                   : "linear-gradient(45deg, purple, orange)", // Gradient when drawer is closed
//                 transition: "background 0.3s ease",
//               }}
//             >
//               <MenuIcon />
//             </IconButton>

//             {/* Main logo before the title */}
//             <img
//               src="/icons/log.png" // Path to log.png
//               alt="Main Logo"
//               style={{
//                 height: "30px", // Adjust the height of the logo
//                 marginRight: "15px", // Space between logo and the text
//               }}
//             />

//             {/* LMH Report Title */}
//             <Typography
//               variant="h6"
//               style={{
//                 flexGrow: 1,
//                 color: "#000",
//                 fontFamily: "Comic Sans MS",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center", // Center the title
//               }}
//             >
//               <img
//                 src="/icons/logo.png" // Path to logo.png
//                 alt="LMH Logo"
//                 style={{
//                   height: "40px", // Adjust the height of the logo
//                   marginRight: "10px", // Space between logo and text
//                 }}
//               />
//               LMH REPORT
//             </Typography>

//             {/* Date and Time Box */}
//             <div style={dateTimeBoxStyle}>
//               <img
//                 src="/icons/calendar.png" // Path to calendar.png
//                 alt="Calendar Icon"
//                 style={{ height: "20px" }} // Adjust the height of the icon
//               />
//               <Typography
//                 variant="body1"
//                 style={{
//                   fontSize: "14px",
//                   fontFamily: "Comic Sans MS",
//                 }}
//               >
//                 {currentDateTime.toLocaleDateString("en-US", {
//                   month: "short",
//                   day: "2-digit",
//                   year: "numeric",
//                 })}{" "}
//                 {currentDateTime.toLocaleTimeString("en-US")}
//               </Typography>
//             </div>
//           </Toolbar>
//         </AppBar>

//         {/* Drawer */}
//         <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//           <List>
//             <ListItem
//               button
//               component={Link}
//               to="/SortName"
//               onClick={() => handleItemClick("SortName")}
//               style={{
//                 background:
//                   selectedItem === "SortName"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <Description
//                   style={{
//                     background:
//                       selectedItem === "SortName"
//                         ? "linear-gradient(45deg, blue, purple)" // Icon gradient
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Max demand report"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>
//             <ListItem
//               button
//               component={Link}
//               to="/StudentReportDownloader"
//               onClick={() => handleItemClick("StudentReportDownloader")}
//               style={{
//                 background:
//                   selectedItem === "StudentReportDownloader"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <Description
//                   style={{
//                     background:
//                       selectedItem === "StudentReportDownloader"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Student Bill Report"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>
//             <ListItem
//               button
//               component={Link}
//               to="/DatePickerComponent"
//               onClick={() => handleItemClick("DatePickerComponent")}
//               style={{
//                 background:
//                   selectedItem === "DatePickerComponent"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <AcUnitOutlined
//                   style={{
//                     background:
//                       selectedItem === "DatePickerComponent"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Billing Monthly"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>
//             <ListItem button onClick={handleLogoutCampus}>
//               <ListItemIcon>
//                 <Logout />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Logout"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>
//           </List>
//         </Drawer>

//         {/* Routes */}
//         <div className="container-fluid p-1 ml-2" style={{ flexGrow: 1 }}>
//           <Routes>
//             <Route path="/SortName" element={<SortName />} />
//             <Route
//               path="/StudentReportDownloader"
//               element={<StudentReportDownloader />}
//             />
//             <Route
//               path="/DatePickerComponent"
//               element={<DatePickerComponent />}
//             />
//           </Routes>
//         </div>

//         {/* Footer */}
//         <div
//           className="footer"
//           style={{
//             backgroundColor: "#ccc", // Light gray background for the footer
//             color: "#000",
//             padding: "10px",
//             textAlign: "center",
//           }}
//         >
//           <p className="p-0 m-0" style={{ display: "inline-block" }}>
//             © 2024 Copyright: conceptualized, Designed, Installed & Maintained
//             By <strong> Heta Datain</strong>
//           </p>
//         </div>
//       </div>
//     </Router>
//   );
// };

// export default Main;
// import React, { useState, useEffect } from "react";
// import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
// } from "@mui/material";
// import {
//   Menu as MenuIcon,
//   Logout,
//   AcUnitOutlined,
//   Description,
//   Home as HomeIcon, // Import the home icon
// } from "@mui/icons-material";
// import DatePickerComponent from "./DatePickerComponent";
// import SortName from "./SortName";
// import StudentReportDownloader from "./StudentReportDownloader";
// import Home from "./Home"; // Import your Home component
// import "../style/main.css"; // Your custom styles

// const Main = (props) => {
//   const { login_status } = props;
//   const [currentDateTime, setCurrentDateTime] = useState(new Date());
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(""); // State for selected item

//   const handleLogoutCampus = () => {
//     console.log("Logged out from campus");
//     login_status(false);
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentDateTime(new Date());
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item); // Set selected item when clicked
//   };

//   const dateTimeBoxStyle = {
//     background: selectedItem
//       ? "linear-gradient(45deg, purple, orange)" // Match the gradient with the selected item
//       : "linear-gradient(45deg, purple, orange)", // Default gradient
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "4px",
//     textAlign: "center",
//     display: "flex",
//     alignItems: "center",
//     gap: "10px", // Space between icon and text
//   };

//   return (
//     <Router>
//       <div
//         style={{
//           fontFamily: "Comic Sans MS",
//           display: "flex",
//           flexDirection: "column",
//           minHeight: "100vh",
//         }}
//       >
//         {/* AppBar with custom background color */}
//         <AppBar position="sticky" sx={{ backgroundColor: "#fff" }}>
//           <Toolbar>
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//               sx={{
//                 background: drawerOpen
//                   ? "linear-gradient(45deg,  rgb(236, 158, 236), rgb(185, 156, 103))" // Gradient when drawer is open
//                   : "linear-gradient(45deg, purple, orange)", // Gradient when drawer is closed
//                 transition: "background 0.3s ease",
//               }}
//             >
//               <MenuIcon />
//             </IconButton>

//             {/* Main logo before the title */}
//             <img
//               src="/icons/log.png" // Path to log.png
//               alt="Main Logo"
//               style={{
//                 height: "30px", // Adjust the height of the logo
//                 marginRight: "15px", // Space between logo and the text
//               }}
//             />

//             {/* LMH Report Title */}
//             <Typography
//               variant="h6"
//               style={{
//                 flexGrow: 1,
//                 color: "#000",
//                 fontFamily: "Comic Sans MS",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center", // Center the title
//               }}
//             >
//               <img
//                 src="/icons/logo.png" // Path to logo.png
//                 alt="LMH Logo"
//                 style={{
//                   height: "40px", // Adjust the height of the logo
//                   marginRight: "10px", // Space between logo and text
//                 }}
//               />
//               LMH REPORT
//             </Typography>

//             {/* Date and Time Box */}
//             <div style={dateTimeBoxStyle}>
//               <img
//                 src="/icons/calendar.png" // Path to calendar.png
//                 alt="Calendar Icon"
//                 style={{ height: "20px" }} // Adjust the height of the icon
//               />
//               <Typography
//                 variant="body1"
//                 style={{
//                   fontSize: "14px",
//                   fontFamily: "Comic Sans MS",
//                 }}
//               >
//                 {currentDateTime.toLocaleDateString("en-US", {
//                   month: "short",
//                   day: "2-digit",
//                   year: "numeric",
//                 })}{" "}
//                 {currentDateTime.toLocaleTimeString("en-US")}
//               </Typography>
//             </div>
//           </Toolbar>
//         </AppBar>

//         {/* Drawer */}
//         <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//           <List>
//             {/* Home link */}
//             <ListItem
//               button
//               component={Link}
//               to="/"
//               onClick={() => handleItemClick("Home")}
//               style={{
//                 background:
//                   selectedItem === "Home"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <HomeIcon
//                   style={{
//                     background:
//                       selectedItem === "Home"
//                         ? "linear-gradient(45deg, blue, purple)" // Icon gradient
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Home"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem
//               button
//               component={Link}
//               to="/SortName"
//               onClick={() => handleItemClick("SortName")}
//               style={{
//                 background:
//                   selectedItem === "SortName"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <Description
//                   style={{
//                     background:
//                       selectedItem === "SortName"
//                         ? "linear-gradient(45deg, blue, purple)" // Icon gradient
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Max demand report"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem
//               button
//               component={Link}
//               to="/StudentReportDownloader"
//               onClick={() => handleItemClick("StudentReportDownloader")}
//               style={{
//                 background:
//                   selectedItem === "StudentReportDownloader"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <Description
//                   style={{
//                     background:
//                       selectedItem === "StudentReportDownloader"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Student Bill Report"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem
//               button
//               component={Link}
//               to="/DatePickerComponent"
//               onClick={() => handleItemClick("DatePickerComponent")}
//               style={{
//                 background:
//                   selectedItem === "DatePickerComponent"
//                     ? "linear-gradient(45deg, lightgray, purple)" // Updated background gradient
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <AcUnitOutlined
//                   style={{
//                     background:
//                       selectedItem === "DatePickerComponent"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Billing Monthly"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem button onClick={handleLogoutCampus}>
//               <ListItemIcon>
//                 <Logout />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Logout"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>
//           </List>
//         </Drawer>

//         {/* Main content */}
//         <div className="container-fluid p-1 ml-2" style={{ flexGrow: 1 }}>
//           <Routes>
//             <Route path="/" element={<Home />} /> {/* Default Home route */}
//             <Route path="/SortName" element={<SortName />} />
//             <Route
//               path="/StudentReportDownloader"
//               element={<StudentReportDownloader />}
//             />
//             <Route
//               path="/DatePickerComponent"
//               element={<DatePickerComponent />}
//             />
//           </Routes>
//         </div>

//         {/* Footer */}
//         <div
//           className="footer"
//           style={{
//             backgroundColor: "#ccc", // Light gray background for the footer
//             color: "#000",
//             padding: "10px",`
//             textAlign: "center",
//           }}
//         >
//           <p className="p-0 m-0" style={{ display: "inline-block" }}>
//             © 2024 Copyright: conceptualized, Designed, Installed & Maintained
//             By <strong> Heta Datain</strong>
//           </p>
//         </div>
//       </div>
//     </Router>
//   );
// };

// export default Main;
// import React, { useState, useEffect } from "react";
// import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   Switch,
// } from "@mui/material";
// import {
//   Menu as MenuIcon,
//   Logout,
//   AcUnitOutlined,
//   Description,
//   Home as HomeIcon,
//   Brightness4 as DarkModeIcon,
//   Brightness7 as LightModeIcon,
// } from "@mui/icons-material";
// import DatePickerComponent from "./DatePickerComponent";
// import SortName from "./SortName";
// import StudentReportDownloader from "./StudentReportDownloader";
// import Home from "./Home";
// import "../style/main.css";

// const Main = (props) => {
//   const { login_status } = props;
//   const [currentDateTime, setCurrentDateTime] = useState(new Date());
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState("");
//   const [isDarkMode, setIsDarkMode] = useState(
//     localStorage.getItem("theme") === "dark"
//   );

//   const handleLogoutCampus = () => {
//     console.log("Logged out from campus");
//     login_status(false);
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentDateTime(new Date());
//     }, 1000);
//     return () => clearInterval(intervalId);
//   }, []);

//   // Set theme in local storage
//   useEffect(() => {
//     document.body.style.backgroundColor = isDarkMode ? "#333" : "#fff";
//     localStorage.setItem("theme", isDarkMode ? "dark" : "light");
//   }, [isDarkMode]);

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//   };

//   const handleThemeToggle = () => {
//     setIsDarkMode((prevMode) => !prevMode);
//   };

//   const dateTimeBoxStyle = {
//     background: selectedItem
//       ? "linear-gradient(45deg, purple, orange)"
//       : "linear-gradient(45deg, purple, orange)",
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "4px",
//     textAlign: "center",
//     display: "flex",
//     alignItems: "center",
//     gap: "10px",
//   };

//   const appBarStyles = {
//     backgroundColor: isDarkMode ? "#444" : "#fff",
//     color: isDarkMode ? "#fff" : "#000",
//   };

//   return (
//     <Router>
//       <div
//         style={{
//           fontFamily: "Comic Sans MS",
//           display: "flex",
//           flexDirection: "column",
//           minHeight: "100vh",
//           backgroundColor: isDarkMode ? "#333" : "#fff",
//           color: isDarkMode ? "#fff" : "#000",
//         }}
//       >
//         <AppBar position="sticky" sx={appBarStyles}>
//           <Toolbar>
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//               sx={{
//                 background: drawerOpen
//                   ? "linear-gradient(45deg,  rgb(236, 158, 236), rgb(185, 156, 103))"
//                   : "linear-gradient(45deg, purple, orange)",
//                 transition: "background 0.3s ease",
//               }}
//             >
//               <MenuIcon />
//             </IconButton>

//             <img
//               src="/icons/log.png"
//               alt="Main Logo"
//               style={{
//                 height: "30px",
//                 marginRight: "15px",
//               }}
//             />

//             <Typography
//               variant="h6"
//               style={{
//                 flexGrow: 1,
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 src="/icons/logo.png"
//                 alt="LMH Logo"
//                 style={{
//                   height: "40px",
//                   marginRight: "10px",
//                 }}
//               />
//               LMH REPORT
//             </Typography>

//             <div style={dateTimeBoxStyle}>
//               <img
//                 src="/icons/calendar.png"
//                 alt="Calendar Icon"
//                 style={{ height: "20px" }}
//               />
//               <Typography
//                 variant="body1"
//                 style={{
//                   fontSize: "14px",
//                   fontFamily: "Comic Sans MS",
//                 }}
//               >
//                 {currentDateTime.toLocaleDateString("en-US", {
//                   month: "short",
//                   day: "2-digit",
//                   year: "numeric",
//                 })}{" "}
//                 {currentDateTime.toLocaleTimeString("en-US")}
//               </Typography>
//             </div>

//             {/* Theme toggle */}
//             <IconButton color="inherit" onClick={handleThemeToggle}>
//               {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
//             </IconButton>
//           </Toolbar>
//         </AppBar>

//         <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//           <List>
//             <ListItem
//               button
//               component={Link}
//               to="/"
//               onClick={() => handleItemClick("Home")}
//               style={{
//                 background:
//                   selectedItem === "Home"
//                     ? "linear-gradient(45deg, lightgray, purple)"
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <HomeIcon
//                   style={{
//                     background:
//                       selectedItem === "Home"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Home"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem
//               button
//               component={Link}
//               to="/SortName"
//               onClick={() => handleItemClick("SortName")}
//               style={{
//                 background:
//                   selectedItem === "SortName"
//                     ? "linear-gradient(45deg, lightgray, purple)"
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <Description
//                   style={{
//                     background:
//                       selectedItem === "SortName"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Max demand report"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem
//               button
//               component={Link}
//               to="/StudentReportDownloader"
//               onClick={() => handleItemClick("StudentReportDownloader")}
//               style={{
//                 background:
//                   selectedItem === "StudentReportDownloader"
//                     ? "linear-gradient(45deg, lightgray, purple)"
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <Description
//                   style={{
//                     background:
//                       selectedItem === "StudentReportDownloader"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Student Bill Report"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem
//               button
//               component={Link}
//               to="/DatePickerComponent"
//               onClick={() => handleItemClick("DatePickerComponent")}
//               style={{
//                 background:
//                   selectedItem === "DatePickerComponent"
//                     ? "linear-gradient(45deg, lightgray, purple)"
//                     : "inherit",
//               }}
//             >
//               <ListItemIcon>
//                 <AcUnitOutlined
//                   style={{
//                     background:
//                       selectedItem === "DatePickerComponent"
//                         ? "linear-gradient(45deg, blue, purple)"
//                         : "inherit",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Billing Monthly"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>

//             <ListItem button onClick={handleLogoutCampus}>
//               <ListItemIcon>
//                 <Logout />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Logout"
//                 primaryTypographyProps={{
//                   style: { fontFamily: "Comic Sans MS" },
//                 }}
//               />
//             </ListItem>
//           </List>
//         </Drawer>

//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/SortName" element={<SortName />} />
//           <Route
//             path="/StudentReportDownloader"
//             element={<StudentReportDownloader />}
//           />
//           <Route
//             path="/DatePickerComponent"
//             element={<DatePickerComponent />}
//           />
//         </Routes>
//       </div>
//     </Router>
//   );
// };

// export default Main;
import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  AcUnitOutlined,
  Description,
  Home as HomeIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
} from "@mui/icons-material";
import DatePickerComponent from "./DatePickerComponent";
import SortName from "./SortName";
import StudentReportDownloader from "./StudentReportDownloader";
import Home from "./Home";
import "../style/main.css";

const Main = (props) => {
  const { login_status } = props;
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  const handleLogoutCampus = () => {
    console.log("Logged out from campus");
    login_status(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Set theme in local storage
  useEffect(() => {
    document.body.style.backgroundColor = isDarkMode ? "#333" : "#fff";
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleThemeToggle = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const dateTimeBoxStyle = {
    background: selectedItem
      ? "linear-gradient(45deg, purple, orange)"
      : "linear-gradient(45deg, purple, orange)",
    color: "#fff",
    padding: "10px",
    borderRadius: "4px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const appBarStyles = {
    backgroundColor: isDarkMode ? "#444" : "#fff",
    color: isDarkMode ? "#fff" : "#000",
  };

  return (
    <Router>
      <div
        style={{
          fontFamily: "Comic Sans MS",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: isDarkMode ? "#333" : "#fff",
          color: isDarkMode ? "#fff" : "#000",
        }}
      >
        <AppBar position="sticky" sx={appBarStyles}>
          <Toolbar>
            {/* LMH REPORT title and logo in the center */}
            <Typography
              variant="h6"
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/icons/logo.png"
                alt="LMH Logo"
                style={{
                  height: "40px",
                  marginRight: "5px",
                }}
              />
              LMH REPORT
            </Typography>

            {/* Right side items: date, theme toggle, and log image */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={dateTimeBoxStyle}>
                <img
                  src="/icons/calendar.png"
                  alt="Calendar Icon"
                  style={{ height: "20px" }}
                />
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "14px",
                    fontFamily: "Comic Sans MS",
                  }}
                >
                  {currentDateTime.toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}{" "}
                  {currentDateTime.toLocaleTimeString("en-US")}
                </Typography>
              </div>

              {/* Theme toggle */}
              <IconButton color="inherit" onClick={handleThemeToggle}>
                {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          variant="permanent"
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerOpen ? 240 : 64,
              transition: "width 0.3s ease",
              overflowX: "hidden",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            {/* Menu button inside the drawer */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(!drawerOpen)}
              sx={{
                background: drawerOpen
                  ? "linear-gradient(45deg, rgb(236, 158, 236), rgb(185, 156, 103))"
                  : "linear-gradient(45deg, purple, orange)",
                transition: "background 0.3s ease",
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>

          <List>
            <ListItem
              button
              component={Link}
              to="/"
              onClick={() => handleItemClick("Home")}
              style={{
                background:
                  selectedItem === "Home"
                    ? "linear-gradient(45deg, lightgray, purple)"
                    : "inherit",
              }}
            >
              <ListItemIcon>
                <HomeIcon
                  style={{
                    background:
                      selectedItem === "Home"
                        ? "linear-gradient(45deg, blue, purple)"
                        : "inherit",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <ListItemText
                  primary="Home"
                  primaryTypographyProps={{
                    style: { fontFamily: "Comic Sans MS" },
                  }}
                />
              )}
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/SortName"
              onClick={() => handleItemClick("SortName")}
              style={{
                background:
                  selectedItem === "SortName"
                    ? "linear-gradient(45deg, lightgray, purple)"
                    : "inherit",
              }}
            >
              <ListItemIcon>
                <Description
                  style={{
                    background:
                      selectedItem === "SortName"
                        ? "linear-gradient(45deg, blue, purple)"
                        : "inherit",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <ListItemText
                  primary="Max demand report"
                  primaryTypographyProps={{
                    style: { fontFamily: "Comic Sans MS" },
                  }}
                />
              )}
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/StudentReportDownloader"
              onClick={() => handleItemClick("StudentReportDownloader")}
              style={{
                background:
                  selectedItem === "StudentReportDownloader"
                    ? "linear-gradient(45deg, lightgray, purple)"
                    : "inherit",
              }}
            >
              <ListItemIcon>
                <Description
                  style={{
                    background:
                      selectedItem === "StudentReportDownloader"
                        ? "linear-gradient(45deg, blue, purple)"
                        : "inherit",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <ListItemText
                  primary="Student Bill Report"
                  primaryTypographyProps={{
                    style: { fontFamily: "Comic Sans MS" },
                  }}
                />
              )}
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/DatePickerComponent"
              onClick={() => handleItemClick("DatePickerComponent")}
              style={{
                background:
                  selectedItem === "DatePickerComponent"
                    ? "linear-gradient(45deg, lightgray, purple)"
                    : "inherit",
              }}
            >
              <ListItemIcon>
                <AcUnitOutlined
                  style={{
                    background:
                      selectedItem === "DatePickerComponent"
                        ? "linear-gradient(45deg, blue, purple)"
                        : "inherit",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <ListItemText
                  primary="Billing Monthly"
                  primaryTypographyProps={{
                    style: { fontFamily: "Comic Sans MS" },
                  }}
                />
              )}
            </ListItem>

            {/* Logout button with icon only */}
            <ListItem button onClick={handleLogoutCampus}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
            </ListItem>
          </List>
        </Drawer>

        <main style={{ flex: 1, padding: "20px" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/SortName" element={<SortName />} />
            <Route
              path="/StudentReportDownloader"
              element={<StudentReportDownloader />}
            />
            <Route
              path="/DatePickerComponent"
              element={<DatePickerComponent />}
            />
          </Routes>
        </main>

        <footer
          style={{
            backgroundColor: "#ccc", // Light gray background for the footer
            color: "#000",
            padding: "10px",
            textAlign: "center",
            position: "sticky",
            bottom: 0,
          }}
        >
          <p className="p-0 m-0" style={{ display: "inline-block" }}>
            © 2024 Copyright: conceptualized, Designed, Installed & Maintained
            By <strong> Heta Datain</strong>
          </p>
        </footer>
      </div>
    </Router>
  );
};

export default Main;
