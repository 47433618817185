// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Select from "react-select";

// const StudentReportDownloader = () => {
//   const [studentId, setStudentId] = useState("");
//   const [year, setYear] = useState("");
//   const [students, setStudents] = useState([]);

//   useEffect(() => {
//     // Fetch the list of students for the dropdown
//     axios
//       .get("http://127.0.0.1:8000/api/student-ids")
//       .then((response) => {
//         setStudents(
//           response.data.map((student) => ({
//             value: student.id,
//             label: student.id,
//           }))
//         );
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the students!", error);
//       });
//   }, []);

//   const handleDownload = () => {
//     const url = `http://127.0.0.1:8000/api/reports/${studentId}/${year}`;
//     axios
//       .get(url, { responseType: "blob" })
//       .then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", `${studentId}_report_${year}.xlsx`);
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((error) => {
//         console.error("There was an error downloading the report!", error);
//       });
//   };

//   return (
//     <div>
//       <h2>Download Student Report</h2>
//       <Select
//         options={students}
//         onChange={(option) => setStudentId(option.value)}
//         placeholder="Select Student ID"
//       />
//       <input
//         type="number"
//         value={year}
//         onChange={(e) => setYear(e.target.value)}
//         placeholder="Select Year"
//       />
//       <button onClick={handleDownload}>Download Report</button>
//     </div>
//   );
// };

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Select from "react-select";

// // Generate a range of years for the dropdown
// const generateYears = (startYear, endYear) => {
//   const years = [];
//   for (let year = startYear; year <= endYear; year++) {
//     years.push({ value: year, label: year });
//   }
//   return years;
// };

// const currentYear = new Date().getFullYear();
// const years = generateYears(2000, currentYear);

// const StudentReportDownloader = () => {
//   const [studentId, setStudentId] = useState("");
//   const [year, setYear] = useState(currentYear);
//   const [students, setStudents] = useState([]);

//   useEffect(() => {
//     // Fetch the list of students for the dropdown
//     axios
//       .get("http://127.0.0.1:8000/api/student-ids")
//       .then((response) => {
//         setStudents(
//           response.data.map((student) => ({
//             value: student.id,
//             label: student.id,
//           }))
//         );
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the students!", error);
//       });
//   }, []);

//   const handleDownload = () => {
//     const url = `http://127.0.0.1:8000/api/reports/${studentId}/${year}`;
//     axios
//       .get(url, { responseType: "blob" })
//       .then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", `${studentId}_report_${year}.xlsx`);
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((error) => {
//         console.error("There was an error downloading the report!", error);
//       });
//   };

//   return (
//     <div>
//       <h2>Download Student Report</h2>
//       <Select
//         options={students}
//         onChange={(option) => setStudentId(option.value)}
//         placeholder="Select Student ID"
//       />
//       <Select
//         options={years}
//         defaultValue={{ value: currentYear, label: currentYear }}
//         onChange={(option) => setYear(option.value)}
//         placeholder="Select Year"
//       />
//       <button onClick={handleDownload}>Download Report</button>
//     </div>
//   );
// };

// export default StudentReportDownloader;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Select from "react-select";

// // Generate a range of years for the dropdown
// const generateYears = (startYear, endYear) => {
//   const years = [];
//   for (let year = startYear; year <= endYear; year++) {
//     years.push({ value: year, label: year });
//   }
//   return years;
// };

// const currentYear = new Date().getFullYear();
// const years = generateYears(2000, currentYear);

// const StudentReportDownloader = () => {
//   const [studentId, setStudentId] = useState("");
//   const [year, setYear] = useState(currentYear);
//   const [students, setStudents] = useState([]);

//   useEffect(() => {
//     // Fetch the list of students for the dropdown
//     axios
//       .get("http://127.0.0.1:8000/api/student-ids")
//       .then((response) => {
//         setStudents(
//           response.data.map((student) => ({
//             value: student.student_id,
//             label: student.student_id,
//           }))
//         );
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the students!", error);
//       });
//   }, []);

//   const handleDownload = () => {
//     const url = `http://127.0.0.1:8000/api/report/${studentId}/${year}`;
//     axios
//       .get(url, { responseType: "blob" })
//       .then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", `${studentId}_report_${year}.xlsx`);
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((error) => {
//         console.error("There was an error downloading the report!", error);
//       });
//   };

//   return (
//     <div>
//       <h2>Download Student Report</h2>
//       <Select
//         options={students}
//         onChange={(option) => setStudentId(option.value)}
//         placeholder="Select Student ID"
//       />
//       <Select
//         options={years}
//         defaultValue={{ value: currentYear, label: currentYear }}
//         onChange={(option) => setYear(option.value)}
//         placeholder="Select Year"
//       />
//       <button onClick={handleDownload}>Download Report</button>
//     </div>
//   );
// };

// export default StudentReportDownloader;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Select from "react-select";
// import "../style/StudentReportDownloader.css"; // Import your CSS file

// const generateYears = (startYear, endYear) => {
//   const years = [];
//   for (let year = startYear; year <= endYear; year++) {
//     years.push({ value: year, label: year });
//   }
//   return years;
// };

// const currentYear = new Date().getFullYear();
// const years = generateYears(2000, currentYear);

// const StudentReportDownloader = () => {
//   const [studentId, setStudentId] = useState("");
//   const [year, setYear] = useState(currentYear);
//   const [students, setStudents] = useState([]);

//   useEffect(() => {
//     axios
//       .get("http://127.0.0.1:8000/api/student-ids")
//       .then((response) => {
//         setStudents(
//           response.data.map((student) => ({
//             value: student.student_id,
//             label: student.student_id,
//           }))
//         );
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the students!", error);
//       });
//   }, []);

//   const handleDownload = () => {
//     const url = `http://127.0.0.1:8000/api/report/${studentId}/${year}`;
//     axios
//       .get(url, { responseType: "blob" })
//       .then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", `${studentId}_report_${year}.xlsx`);
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((error) => {
//         console.error("There was an error downloading the report!", error);
//       });
//   };

//   return (
//     <div className="centered-container">
//       <div className="report-downloader-container">
//         <h2>Download Student Report</h2>
//         <Select
//           options={students}
//           onChange={(option) => setStudentId(option.value)}
//           placeholder="Select Student ID"
//           className="select-box"
//         />
//         <Select
//           options={years}
//           defaultValue={{ value: currentYear, label: currentYear }}
//           onChange={(option) => setYear(option.value)}
//           placeholder="Select Year"
//           className="select-box"
//         />
//         <button onClick={handleDownload} className="download-button">
//           Download Report
//         </button>
//       </div>
//     </div>
//   );
// };

// export default StudentReportDownloader;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Select from "react-select";
// import "../style/StudentReportDownloader.css"; // Import your CSS file
// import url from "../routes/api";

// const generateYears = (startYear, endYear) => {
//   const years = [];
//   for (let year = startYear; year <= endYear; year++) {
//     years.push({ value: year, label: year });
//   }
//   return years;
// };

// const currentYear = new Date().getFullYear();
// const years = generateYears(2000, currentYear);

// const StudentReportDownloader = () => {
//   const [studentId, setStudentId] = useState("");
//   const [year, setYear] = useState(currentYear);
//   const [students, setStudents] = useState([]);

//   useEffect(() => {
//     axios
//       .get(`${url}student-ids`) // Append path to base URL
//       .then((response) => {
//         setStudents(
//           response.data.map((student) => ({
//             value: student.student_id,
//             label: student.student_id,
//           }))
//         );
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the students!", error);
//       });
//   }, []);

//   const handleDownload = () => {
//     const url = `${url}report/${studentId}/${year}`; // Append path to base URL
//     axios
//       .get(url, { responseType: "blob" })
//       .then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", `${studentId}_report_${year}.xlsx`);
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((error) => {
//         console.error("There was an error downloading the report!", error);
//       });
//   };

//   return (
//     <div className="centered-container">
//       <div className="report-downloader-container">
//         <h2>Download Student Report</h2>
//         <Select
//           options={students}
//           onChange={(option) => setStudentId(option.value)}
//           placeholder="Select Student ID"
//           className="select-box"
//         />
//         <Select
//           options={years}
//           defaultValue={{ value: currentYear, label: currentYear }}
//           onChange={(option) => setYear(option.value)}
//           placeholder="Select Year"
//           className="select-box"
//         />
//         <button onClick={handleDownload} className="download-button">
//           Download Report
//         </button>
//       </div>
//     </div>
//   );
// };

// export default StudentReportDownloader;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "../style/StudentReportDownloader.css"; // Import your CSS file
import apiUrl from "../routes/api"; // Renamed imported variable to avoid shadowing

const generateYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ value: year, label: year });
  }
  return years;
};

const currentYear = new Date().getFullYear();
const years = generateYears(2000, currentYear);

const StudentReportDownloader = () => {
  const [studentId, setStudentId] = useState("");
  const [year, setYear] = useState(currentYear);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}student-ids`) // Use renamed imported variable
      .then((response) => {
        setStudents(
          response.data.map((student) => ({
            value: student.student_id,
            label: student.student_id,
          }))
        );
      })
      .catch((error) => {
        console.error("There was an error fetching the students!", error);
      });
  }, []);

  const handleDownload = () => {
    const downloadUrl = `${apiUrl}report/${studentId}/${year}`; // Use a different variable name for the URL inside the function
    axios
      .get(downloadUrl, { responseType: "blob" })
      .then((response) => {
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", `${studentId}_report_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("There was an error downloading the report!", error);
      });
  };

  return (
    <div className="centered-container">
      <div className="report-downloader-container">
        <h2>Download Student Report</h2>
        <Select
          options={students}
          onChange={(option) => setStudentId(option.value)}
          placeholder="Select Student ID"
          className="select-box"
        />
        <Select
          options={years}
          defaultValue={{ value: currentYear, label: currentYear }}
          onChange={(option) => setYear(option.value)}
          placeholder="Select Year"
          className="select-box"
        />
        <button onClick={handleDownload} className="download-button">
          Download Report
        </button>
      </div>
    </div>
  );
};

export default StudentReportDownloader;
