import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "bootstrap";
import "../style/Home.css";
import url from "../routes/api";

const Home = () => {
  const windowHeight = window.innerHeight;
  const [images, setImages] = useState([]);

  const biReports = async () => {
    try {
      const response = await fetch(`${url}bi`);
      if (!response.ok) {
        throw new Error("Failed to fetch reports");
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  useEffect(() => {
    biReports();
  }, []);

  // Initialize the carousel when the component mounts
  React.useEffect(() => {
    const carouselElement = document.getElementById("demo");
    const carousel = new Carousel(carouselElement, {
      interval: 2000, // Set the interval between slides (in milliseconds)
      keyboard: true, // Enable keyboard navigation
    });

    return () => {
      // Clean up the carousel when the component unmounts
      carousel.dispose();
    };
  }, []); // Empty dependency array to run this effect only once when the component mounts

  return (
    <div id="demo" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : "false"}
            aria-label={`Slide ${index}`}
            style={{ color: "black" }}
          />
        ))}
      </div>

      <div className="carousel-inner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <img
              src={image.src}
              className="container-fluid"
              style={{ maxHeight: windowHeight / 1.3 }}
              alt={`Slide ${index}`}
            />
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#demo"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#demo"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Home;
// import React, { useState } from "react";
// import "../style/Home.css"; // Assuming styles are in Home.css

// const Home = () => {
//   // State for popup
//   const [selectedImage, setSelectedImage] = useState(null);

//   // List of image links
//   const imageLinks = [
//     "https://lmh-reports.hetadatain.com/bi_reports/image5.png",
//     "https://lmh-reports.hetadatain.com/bi_reports/image2.png",
//     "https://lmh-reports.hetadatain.com/bi_reports/image3.png",
//     "https://lmh-reports.hetadatain.com/bi_reports/image4.png",
//   ];

//   // Handler to show popup
//   const handleIconClick = (imageLink) => {
//     setSelectedImage(imageLink);
//   };

//   // Close the popup
//   const closePopup = () => {
//     setSelectedImage(null);
//   };

//   return (
//     <div className="home-container">
//       {/* Horizontal card container */}
//       <div className="card-container">
//         {/* Mapping over imageLinks to create cards */}
//         {imageLinks.map((link, index) => (
//           <div className="card" key={index}>
//             {/* Image with icon */}
//             <img
//               src="/icons/graph.png" // Path to the icon
//               alt="Graph Icon"
//               className="graph-icon"
//               onClick={() => handleIconClick(link)}
//             />
//             {/* Label for each card */}
//             <p>Card {index + 1}</p>
//           </div>
//         ))}
//       </div>

//       {/* Popup Component */}
//       {selectedImage && (
//         <div className="popup">
//           <div className="popup-content">
//             {/* Close button */}
//             <span className="close-popup" onClick={closePopup}>
//               &times;
//             </span>
//             {/* Display selected image */}
//             <img src={selectedImage} alt="Graph" className="popup-image" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Home;
