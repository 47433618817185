// // DatePickerComponent.js
// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const DatePickerComponent = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleDownload = () => {
//     const year = selectedDate.getFullYear();
//     const month = selectedDate.getMonth() + 1; // Months are zero-indexed

//     const url = `http://127.0.0.1:8000/api/total/${year}/${month}`;

//     fetch(url)
//       .then(response => response.blob())
//       .then(blob => {
//         const link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         link.download = `data_${year}_${month}.csv`;
//         link.click();
//       })
//       .catch(error => console.error('Error fetching the data:', error));
//   };

//   return (
//     <div>
//       <DatePicker
//         selected={selectedDate}
//         onChange={handleDateChange}
//         dateFormat="yyyy/MM"
//         showMonthYearPicker
//       />
//       <button onClick={handleDownload}>Download</button>
//     </div>
//   );
// };

// export default DatePickerComponent;
// DatePickerComponent.js
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "../style/DatePickerComponent.css";
// import url from "../routes/api"; // Ensure this import points to the correct file

// const DatePickerComponent = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleDownload = () => {
//     const year = selectedDate.getFullYear();
//     const month = selectedDate.getMonth() + 1; // Months are zero-indexed

//     const apiUrl = `${url}total/${year}/${month}`;

//     fetch(apiUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = `data_${year}_${month}.csv`;
//         link.click();
//       })
//       .catch((error) => console.error("Error fetching the data:", error));
//   };

//   return (
//     <div className="calendar-container">
//       <DatePicker
//         selected={selectedDate}
//         onChange={handleDateChange}
//         dateFormat="yyyy/MM"
//         showMonthYearPicker
//         className="calendar-input"
//       />
//       <button onClick={handleDownload} className="download-btn">
//         Download
//       </button>
//     </div>
//   );
// };

// export default DatePickerComponent;
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "../style/DatePickerComponent.css";
// import url from "../routes/api"; // Ensure this import points to the correct file

// const DatePickerComponent = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleDownload = () => {
//     const year = selectedDate.getFullYear();
//     const month = selectedDate.getMonth() + 1; // Months are zero-indexed

//     const apiUrl = `${url}total/${year}/${month}`;

//     fetch(apiUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = `data_${year}_${month}.csv`;
//         link.click();
//       })
//       .catch((error) => console.error("Error fetching the data:", error));
//   };

//   return (
//     <div className="calendar-container">
//       <DatePicker
//         selected={selectedDate}
//         onChange={handleDateChange}
//         dateFormat="yyyy/MM"
//         showMonthYearPicker
//         className="calendar-input"
//       />
//       <button onClick={handleDownload} className="download-btn">
//         Download
//       </button>
//     </div>
//   );
// };

// export default DatePickerComponent;
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "../style/DatePickerComponent.css";
// import url from "../routes/api"; // Ensure this import points to the correct file

// const DatePickerComponent = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [reportName, setReportName] = useState(""); // Default to empty for placeholder

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleReportChange = (event) => {
//     setReportName(event.target.value);
//   };

//   const handleDownload = () => {
//     const year = selectedDate.getFullYear();
//     const month = selectedDate.getMonth() + 1; // Months are zero-indexed

//     // Construct the API URL with the selected reportName
//     const apiUrl = `${url}${reportName}/${year}/${month}`;

//     fetch(apiUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = `Total_Billing_Report`; // Updated download file name
//         link.click();
//       })
//       .catch((error) => console.error("Error fetching the data:", error));
//   };

//   return (
//     <div className="calendar-container">
//       <div className="input-container">
//         <div className="input-group">
//           <label htmlFor="report-select" className="input-label">
//             Select Report
//           </label>
//           <select
//             id="report-select"
//             value={reportName}
//             onChange={handleReportChange}
//             className="report-select"
//           >
//             <option value="" disabled>
//               Select Report
//             </option>
//             {/* Option for Total Billing */}
//             <option value="total">Total Billing</option>
//             {/* Add more options as needed */}
//           </select>
//         </div>

//         <div className="input-group">
//           <label htmlFor="date-picker" className="input-label">
//             Select Date
//           </label>
//           <DatePicker
//             id="date-picker"
//             selected={selectedDate}
//             onChange={handleDateChange}
//             dateFormat="yyyy/MM"
//             showMonthYearPicker
//             className="calendar-input"
//           />
//         </div>
//       </div>
//       <button onClick={handleDownload} className="download-btn">
//         Download
//       </button>
//     </div>
//   );
// };

// export default DatePickerComponent;
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "../style/DatePickerComponent.css";
// import url from "../routes/api"; // Ensure this import points to the correct file

// const DatePickerComponent = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [reportName, setReportName] = useState(""); // Default to empty for placeholder

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleReportChange = (event) => {
//     setReportName(event.target.value);
//   };

//   const handleDownload = () => {
//     const year = selectedDate.getFullYear();
//     const month = selectedDate.getMonth() + 1; // Months are zero-indexed

//     let apiUrl = "";

//     if (reportName === "total") {
//       apiUrl = `${url}${reportName}/${year}/${month}`;
//     } else if (reportName === "student_count") {
//       apiUrl = `https://lmh-reports.hetadatain.com/api/record/${year}/${month}`;
//     }

//     fetch(apiUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = `${reportName.replace("_", " ").toUpperCase()}_Report`; // Dynamic file name based on report type
//         link.click();
//       })
//       .catch((error) => console.error("Error fetching the data:", error));
//   };

//   return (
//     <div className="calendar-container">
//       <div className="input-container">
//         <div className="input-group">
//           <label htmlFor="report-select" className="input-label">
//             Select Report
//           </label>
//           <select
//             id="report-select"
//             value={reportName}
//             onChange={handleReportChange}
//             className="report-select"
//           >
//             <option value="" disabled>
//               Select Report
//             </option>
//             {/* Option for Total Billing */}
//             <option value="total">Total Billing</option>
//             {/* Option for Student Count Report */}
//             <option value="student_count">Student Count Report</option>
//             {/* Add more options as needed */}
//           </select>
//         </div>

//         <div className="input-group">
//           <label htmlFor="date-picker" className="input-label">
//             Select Date
//           </label>
//           <DatePicker
//             id="date-picker"
//             selected={selectedDate}
//             onChange={handleDateChange}
//             dateFormat="yyyy/MM"
//             showMonthYearPicker
//             className="calendar-input"
//           />
//         </div>
//       </div>
//       <button onClick={handleDownload} className="download-btn">
//         Download
//       </button>
//     </div>
//   );
// };

// export default DatePickerComponent;
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "../style/DatePickerComponent.css";
// import url from "../routes/api"; // Ensure this import points to the correct file

// const DatePickerComponent = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [reportName, setReportName] = useState(""); // Default to empty for placeholder

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleReportChange = (event) => {
//     setReportName(event.target.value);
//   };

//   const handleDownload = () => {
//     const year = selectedDate.getFullYear();
//     const month = selectedDate.getMonth() + 1; // Months are zero-indexed

//     let apiUrl = "";

//     if (reportName === "total") {
//       apiUrl = `${url}${reportName}/${year}/${month}`;
//     } else if (reportName === "student_count") {
//       apiUrl = `https://lmh-reports.hetadatain.com/api/record/${year}/${month}`;
//     } else if (reportName === "campus_monthly_status_last_date") {
//       apiUrl = `https://lmh-reports.hetadatain.com/api/Device_monthly/${year}/${month}`;
//     } else if (reportName === "Biller_monthly_status_last_date") {
//       apiUrl = `https://lmh-reports.hetadatain.com/api/Biller_monthly/${year}/${month}`;
//     }

//     fetch(apiUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = `${reportName.replace("_", " ").toUpperCase()}_Report`; // Dynamic file name based on report type
//         link.click();
//       })
//       .catch((error) => console.error("Error fetching the data:", error));
//   };

//   return (
//     <div className="calendar-container">
//       <div className="input-container">
//         <div className="input-group">
//           <label htmlFor="report-select" className="input-label">
//             Select Report
//           </label>
//           <select
//             id="report-select"
//             value={reportName}
//             onChange={handleReportChange}
//             className="report-select"
//           >
//             <option value="" disabled>
//               Select Report
//             </option>
//             {/* Option for Total Billing */}
//             <option value="total">Total Billing</option>
//             {/* Option for Student Count Report */}
//             <option value="student_count">Student Count Report</option>
//             {/* Option for Device Monthly Report */}
//             <option value="campus_monthly_status_last_date">
//               Campus Monthly Report
//             </option>
//             {/* Option for Biller Monthly Report */}
//             <option value="Biller_monthly_status_last_date">
//               Biller Monthly Report
//             </option>
//             {/* Add more options as needed */}
//           </select>
//         </div>

//         <div className="input-group">
//           <label htmlFor="date-picker" className="input-label">
//             Select Date
//           </label>
//           <DatePicker
//             id="date-picker"
//             selected={selectedDate}
//             onChange={handleDateChange}
//             dateFormat="yyyy/MM"
//             showMonthYearPicker
//             className="calendar-input"
//           />
//         </div>
//       </div>
//       <button onClick={handleDownload} className="download-btn">
//         Download
//       </button>
//     </div>
//   );
// };

// export default DatePickerComponent;
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style/DatePickerComponent.css";
import url from "../routes/api"; // Ensure this import points to the correct file

const DatePickerComponent = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reportName, setReportName] = useState(""); // Default to empty for placeholder
  const [loading, setLoading] = useState(false); // Add loading state

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleReportChange = (event) => {
    setReportName(event.target.value);
  };

  const handleDownload = () => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // Months are zero-indexed

    let apiUrl = "";

    if (reportName === "total") {
      apiUrl = `${url}${reportName}/${year}/${month}`;
    } else if (reportName === "student_count") {
      apiUrl = `https://lmh-reports.hetadatain.com/api/record/${year}/${month}`;
    } else if (reportName === "campus_monthly_status_last_date") {
      apiUrl = `https://lmh-reports.hetadatain.com/api/Device_monthly/${year}/${month}`;
    } else if (reportName === "Biller_monthly_status_last_date") {
      apiUrl = `https://lmh-reports.hetadatain.com/api/Biller_monthly/${year}/${month}`;
    } else if (reportName === "negativehostelbiller") {
      apiUrl = `https://lmh-reports.hetadatain.com/api/negative/${year}/${month}`;
    }

    setLoading(true); // Set loading to true when request starts

    fetch(apiUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${reportName.replace("_", " ").toUpperCase()}_Report`; // Dynamic file name based on report type
        link.click();
      })
      .catch((error) => console.error("Error fetching the data:", error))
      .finally(() => {
        setLoading(false); // Set loading to false when request finishes
      });
  };

  return (
    <div className="calendar-container">
      <div className="input-container">
        <div className="input-group">
          <label htmlFor="report-select" className="input-label">
            Select Report
          </label>
          <select
            id="report-select"
            value={reportName}
            onChange={handleReportChange}
            className="report-select"
          >
            <option value="" disabled>
              Select Report
            </option>
            <option value="total">Total Billing</option>
            <option value="student_count">Student Count Report</option>
            <option value="campus_monthly_status_last_date">
              Campus Data Status
            </option>
            <option value="Biller_monthly_status_last_date">
              Biller Data Status
            </option>
            <option value="negativehostelbiller">Negative Hostel Biller</option>
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="date-picker" className="input-label">
            Select Date
          </label>
          <DatePicker
            id="date-picker"
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy/MM"
            showMonthYearPicker
            className="calendar-input"
          />
        </div>
      </div>
      <button
        onClick={handleDownload}
        className="download-btn"
        disabled={loading}
      >
        {loading ? <div className="loading-circle"></div> : "Download"}
      </button>
    </div>
  );
};

export default DatePickerComponent;
