// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
// import "../style/SortName.css";
// import url from "../routes/api"; // Adjust the import path as needed

// const SortName = () => {
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [reportType, setReportType] = useState(""); // Default to empty string
//   const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

//   const handleGenerateReport = () => {
//     if (startDate && endDate && reportType) {
//       setIsLoading(true); // Set loading to true before API call

//       const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
//       const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

//       const apiUrl = `${url}export-lmhs/${formattedStartDate}/${formattedEndDate}`;

//       fetch(apiUrl)
//         .then((response) => response.blob())
//         .then((blob) => {
//           const url = window.URL.createObjectURL(new Blob([blob]));
//           const a = document.createElement("a");
//           a.href = url;
//           a.download = `${reportType}_report.xlsx`;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//           setIsLoading(false); // Set loading to false after API call
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//           alert("Error generating report. Please try again.");
//           setIsLoading(false); // Set loading to false if there is an error
//         });
//     } else {
//       alert("Please select report type, start and end dates.");
//     }
//   };

//   return (
//     <div className="sort-name-container">
//       <h2 style={{ fontFamily: '"Comic Sans MS", cursive, sans-serif' }}>
//         Maximum Demand Report
//       </h2>
//       <div className="controls-container">
//         <div className="report-type-dropdown">
//           <label className="comic-sans">Select Report </label>
//           <select
//             value={reportType}
//             onChange={(e) => setReportType(e.target.value)}
//             style={{ fontFamily: '"Comic Sans MS", cursive, sans-serif' }}
//           >
//             <option
//               value=""
//               disabled
//               style={{
//                 fontFamily: '"Comic Sans MS", cursive, sans-serif',
//                 color: "lightgrey",
//               }}
//             >
//               Select Report
//             </option>
//             <option
//               value="kw_consp"
//               style={{ fontFamily: '"Comic Sans MS", cursive, sans-serif' }}
//             >
//               KW Consumption Report
//             </option>
//           </select>
//         </div>
//         <div className="date-picker-container">
//           <div className="date-picker">
//             <label className="comic-sans">Select Start Date </label>
//             <DatePicker
//               selected={startDate}
//               onChange={(date) => setStartDate(date)}
//               selectsStart
//               startDate={startDate}
//               endDate={endDate}
//               placeholderText="Select start date"
//             />
//           </div>
//           <div className="date-picker">
//             <label className="comic-sans">Select End Date </label>
//             <DatePicker
//               selected={endDate}
//               onChange={(date) => setEndDate(date)}
//               selectsEnd
//               startDate={startDate}
//               endDate={endDate}
//               minDate={startDate}
//               placeholderText="Select end date"
//             />
//           </div>
//         </div>
//       </div>
//       <button onClick={handleGenerateReport} disabled={isLoading}>
//         {isLoading ? "Generating..." : "Generate Report"}
//       </button>
//       {isLoading && <div className="loading-indicator">Loading...</div>}
//     </div>
//   );
// };

// export default SortName;
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
// import "../style/SortName.css";
// import url from "../routes/api"; // Adjust the import path as needed

// const reportTypes = [
//   "KW Consumption Report",
//   // Add other report types here if needed
// ];

// const SortName = () => {
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [reportType, setReportType] = useState(""); // Default to empty string
//   const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
//   const [primaryButtonName, setPrimaryButtonName] = useState("Generate Report"); // Primary button name
//   const [secondaryButtonName, setSecondaryButtonName] = useState("Reset Form"); // Secondary button name

//   const handleGenerateReport = () => {
//     if (startDate && endDate && reportType) {
//       setIsLoading(true); // Set loading to true before API call
//       setPrimaryButtonName("Generating..."); // Change button name to indicate loading

//       const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
//       const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

//       const apiUrl = `${url}export-lmhs/${formattedStartDate}/${formattedEndDate}`;

//       fetch(apiUrl)
//         .then((response) => response.blob())
//         .then((blob) => {
//           const url = window.URL.createObjectURL(new Blob([blob]));
//           const a = document.createElement("a");
//           a.href = url;
//           // Generate a dynamic file name based on report type and date range
//           const fileName = `${reportType.replace(
//             /_/g,
//             " "
//           )}_${formattedStartDate}_to_${formattedEndDate}.xlsx`;
//           a.download = fileName;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//           setIsLoading(false); // Set loading to false after API call
//           setPrimaryButtonName("Submit"); // Set button name to "Submit" after completion
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//           alert("Error generating report. Please try again.");
//           setIsLoading(false); // Set loading to false if there is an error
//           setPrimaryButtonName("Generate Report"); // Reset button name after error
//         });
//     } else {
//       alert("Please select report type, start and end dates.");
//     }
//   };

//   const handleResetForm = () => {
//     setStartDate(null);
//     setEndDate(null);
//     setReportType("");
//     setPrimaryButtonName("Generate Report"); // Reset button name when form is reset
//   };

//   return (
//     <div className="sort-name-container">
//       <div className="controls-container">
//         <div className="inputs-container">
//           <div className="report-type-dropdown">
//             <label className="comic-sans">Select Report</label>
//             <select
//               className="select-report"
//               value={reportType}
//               onChange={(e) => setReportType(e.target.value)}
//             >
//               <option value="" disabled>
//                 Select Report
//               </option>
//               {reportTypes.map((type, index) => (
//                 <option
//                   key={index}
//                   value={type.toLowerCase().replace(/\s+/g, "_")}
//                 >
//                   {type}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className="date-picker">
//             <label className="comic-sans">Select Start Date</label>
//             <DatePicker
//               className="date-picker-input"
//               selected={startDate}
//               onChange={(date) => setStartDate(date)}
//               selectsStart
//               startDate={startDate}
//               endDate={endDate}
//               placeholderText="Select start date"
//             />
//           </div>

//           <div className="date-picker">
//             <label className="comic-sans">Select End Date</label>
//             <DatePicker
//               className="date-picker-input"
//               selected={endDate}
//               onChange={(date) => setEndDate(date)}
//               selectsEnd
//               startDate={startDate}
//               endDate={endDate}
//               minDate={startDate}
//               placeholderText="Select end date"
//             />
//           </div>
//         </div>

//         <div className="button-container">
//           <button onClick={handleGenerateReport} disabled={isLoading}>
//             {primaryButtonName}
//           </button>
//           <button onClick={handleResetForm} disabled={isLoading}>
//             {secondaryButtonName}
//           </button>
//         </div>

//         {isLoading && <div className="loading-indicator">Loading...</div>}
//       </div>
//     </div>
//   );
// };

// export default SortName;
// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
// import "../style/SortName.css";
// import url from "../routes/api"; // Adjust the import path as needed

// const reportTypes = [
//   "KW Consumption Report",
//   // Add other report types here if needed
// ];

// const SortName = () => {
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [reportType, setReportType] = useState(""); // Default to empty string
//   const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
//   const [primaryButtonName, setPrimaryButtonName] = useState("Generate Report"); // Primary button name

//   const handleGenerateReport = () => {
//     if (startDate && endDate && reportType) {
//       setIsLoading(true); // Set loading to true before API call
//       setPrimaryButtonName("Generating..."); // Change button name to indicate loading

//       const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
//       const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

//       const apiUrl = `${url}export-lmhs/${formattedStartDate}/${formattedEndDate}`;

//       fetch(apiUrl)
//         .then((response) => response.blob())
//         .then((blob) => {
//           const url = window.URL.createObjectURL(new Blob([blob]));
//           const a = document.createElement("a");
//           a.href = url;
//           // Generate a dynamic file name based on report type and date range
//           const fileName = `${reportType.replace(
//             /_/g,
//             " "
//           )}_${formattedStartDate}_to_${formattedEndDate}.xlsx`;
//           a.download = fileName;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//           setIsLoading(false); // Set loading to false after API call
//           setPrimaryButtonName("Submit"); // Set button name to "Submit" after completion
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//           alert("Error generating report. Please try again.");
//           setIsLoading(false); // Set loading to false if there is an error
//           setPrimaryButtonName("Generate Report"); // Reset button name after error
//         });
//     } else {
//       alert("Please select report type, start and end dates.");
//     }
//   };

//   const handleResetForm = () => {
//     setStartDate(null);
//     setEndDate(null);
//     setReportType("");
//     setPrimaryButtonName("Generate Report"); // Reset button name when form is reset
//   };

//   return (
//     <div className="sort-name-container">
//       <div className="controls-container">
//         <div className="inputs-container">
//           <div className="report-type-dropdown">
//             <label className="comic-sans">Select Report</label>
//             <select
//               className="select-report"
//               value={reportType}
//               onChange={(e) => setReportType(e.target.value)}
//             >
//               <option value="" disabled>
//                 Select Report
//               </option>
//               {reportTypes.map((type, index) => (
//                 <option
//                   key={index}
//                   value={type.toLowerCase().replace(/\s+/g, "_")}
//                 >
//                   {type}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className="date-picker">
//             <label className="comic-sans">Select Start Date</label>
//             <DatePicker
//               className="date-picker-input"
//               selected={startDate}
//               onChange={(date) => setStartDate(date)}
//               selectsStart
//               startDate={startDate}
//               endDate={endDate}
//               placeholderText="Select start date"
//             />
//           </div>

//           <div className="date-picker-end-reset">
//             <div className="date-picker">
//               <label className="comic-sans">Select End Date</label>
//               <DatePicker
//                 className="date-picker-input"
//                 selected={endDate}
//                 onChange={(date) => setEndDate(date)}
//                 selectsEnd
//                 startDate={startDate}
//                 endDate={endDate}
//                 minDate={startDate}
//                 placeholderText="Select end date"
//               />
//             </div>
//             <button
//               className="reset-button"
//               onClick={handleResetForm}
//               disabled={isLoading}
//             >
//               &#x21bb; {/* Reset icon (↻) */}
//             </button>
//           </div>
//         </div>

//         <div className="button-container">
//           <button
//             className="primary-button"
//             onClick={handleGenerateReport}
//             disabled={isLoading}
//           >
//             {primaryButtonName}
//           </button>
//         </div>

//         {isLoading && <div className="loading-indicator">Loading...</div>}
//       </div>
//     </div>
//   );
// };

// export default SortName;
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "../style/SortName.css";
import url from "../routes/api"; // Adjust the import path as needed

const reportTypes = [
  "KW Consumption Report",
  // Add other report types here if needed
];

const SortName = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportType, setReportType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [primaryButtonName, setPrimaryButtonName] = useState("Generate Report");

  const handleGenerateReport = () => {
    if (startDate && endDate && reportType) {
      setIsLoading(true);
      setPrimaryButtonName("Generating...");

      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      const apiUrl = `${url}export-lmhs/${formattedStartDate}/${formattedEndDate}`;

      fetch(apiUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          const fileName = `${reportType.replace(
            /_/g,
            " "
          )}_${formattedStartDate}_to_${formattedEndDate}.xlsx`;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setIsLoading(false);
          setPrimaryButtonName("Submit");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          alert("Error generating report. Please try again.");
          setIsLoading(false);
          setPrimaryButtonName("Generate Report");
        });
    } else {
      alert("Please select report type, start and end dates.");
    }
  };

  const handleResetForm = () => {
    setStartDate(null);
    setEndDate(null);
    setReportType("");
    setPrimaryButtonName("Generate Report");
  };

  return (
    <div className="sort-name-container">
      <div className="controls-container">
        <div className="inputs-container">
          <div className="report-type-dropdown">
            <label className="comic-sans">Select Report</label>
            <select
              className="select-report"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <option value="" disabled>
                Select Report
              </option>
              {reportTypes.map((type, index) => (
                <option
                  key={index}
                  value={type.toLowerCase().replace(/\s+/g, "_")}
                >
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="date-picker">
            <label className="comic-sans">Select Start Date</label>
            <div className="date-picker-container">
              <DatePicker
                className="date-picker-input"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Select start date"
              />
              <i className="fas fa-calendar date-picker-icon"></i>
            </div>
          </div>

          <div className="date-picker-end-reset">
            <div className="date-picker">
              <label className="comic-sans">Select End Date</label>
              <div className="date-picker-container">
                <DatePicker
                  className="date-picker-input"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="Select end date"
                />
                <i className="fas fa-calendar date-picker-icon"></i>
              </div>
            </div>
            <button
              className="reset-button"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              &#x21bb;
            </button>
          </div>
        </div>

        <div className="button-container">
          <button
            className="primary-button"
            onClick={handleGenerateReport}
            disabled={isLoading}
          >
            {primaryButtonName}
          </button>
        </div>

        {isLoading && <div className="loading-indicator">Loading...</div>}
      </div>
    </div>
  );
};

export default SortName;
